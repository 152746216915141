import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";

interface AuthRouteGuardProps {
  component: React.ComponentType<any>;
  [key: string]: any;
}

const AuthRouteGuard: React.FC<AuthRouteGuardProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthed, user, setIsAuthed } = useAuth(); // Check if the user is authenticated
  useEffect(() => {}, [isAuthed]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthed ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default AuthRouteGuard;
