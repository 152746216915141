import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";

const popularQuestions = [
  "What is a Porsche 911?",
  "is there a new lambo coming out?",
  "what is the best car?",
  "What color should I get my car?",
  "What is the best car for me?",
];

function PopularQuestions({
  onQuestionSelected,
}: {
  onQuestionSelected: (q: string) => void;
}) {
  return (
    <div className="popular-questions-wrapper">
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          {popularQuestions.map((question, index) => {
            return (
              <IonCol size="6" size-md="3" key={index}>
                <IonCard
                  onClick={() => onQuestionSelected(question)}
                  className="cursor-pointer common-question-card"
                >
                  <IonCardHeader>
                    <IonCardTitle
                      style={{ fontWeight: "900", color: "#464038" }}
                    >
                      {question}
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </div>
  );
}
export default PopularQuestions;
