import DeleteModal from "../components/modals/delete-modal";
import PageLayout from "./PageLayout";
import { useState } from "react";
import { signOutAndRedirect } from "../libs/database";
import { IonButton, useIonRouter } from "@ionic/react";
import { deleteUser } from "../libs/api";
import { SEO } from "../components/SEO";

const AccountPage = ({ user }: { user: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useIonRouter();

  const handleSignOut = async (e: any) => {
    await signOutAndRedirect(e, router.push).then(() => {
      router.push("/");
    });
  };

  const deleteAccount = () => {
    deleteUser(user)
      .then((res) => {
        console.log("deleteUser", res);
        router.push("/");
      })
      .catch((err) => {
        console.log("deleteUser", err);
      });
  };

  return (
    <>
      <SEO title="My Account | Carinfo AI" />
      <PageLayout name={"Account"}>
        <div className="container">
          <div className="account-page-wrapper">
            <div className="py-3 border-bottom">
              <h2>My Account</h2>
            </div>
            <div className="py-3 border-bottom">
              <h4>
                Username :{" "}
                <span
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  {user?.displayName || "Anonymous"}
                </span>
              </h4>
            </div>
            <div className="py-3 border-bottom">
              <h4>
                Email :{" "}
                <span
                  style={{
                    fontWeight: "normal",
                  }}
                >
                  {user?.email}
                </span>
              </h4>
            </div>
            <div
              className="py-3 d-flex ion-justify-content-end flex-wrap"
              style={{
                columnGap: "10px",
                rowGap: "10px",
              }}
            >
              <IonButton color={"light"} onClick={(e) => handleSignOut(e)}>
                Sign Out
              </IonButton>
              <IonButton color={"danger"} onClick={() => setIsOpen(true)}>
                Delete Account
              </IonButton>
            </div>
          </div>
        </div>
        <DeleteModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          variant="account"
          onDeleteClicked={deleteAccount}
        />
      </PageLayout>
    </>
  );
};

export default AccountPage;
