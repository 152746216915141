import React, { useContext, createContext, useEffect, useState } from "react";
import { listenToProjectChats, updateChatName } from "../libs/database";
import {
  PROJECT_ID,
  createNewChat,
  getChatByChatId,
  getAllChatsForUser,
} from "../libs/api";
import { ChatType } from "../libs/types";
import { useAuth } from "./AuthContext";
import { useIonRouter, useIonToast } from "@ionic/react";

export const ChatContext = createContext(
  {} as {
    chats: any;
    messages: any;
    setMessages: any;
    questions: any;
    setQuestions: any;
    input: any;
    setInput: any;
    messageSent: any;
    setMessageSent: any;
    latestMessage: any;
    setLatestMessage: any;
    createChat: any;
    selectedChat: any;
    setSelectedChat: any;
    updateChatTitle: (chat: ChatType, title: string) => void;
    isLoadingChat: boolean;
    setIsLoadingChat: any;
    currentLimit: any;
    disablePagination: boolean;
    setDisablePagination: any;
    setCurrentLimit: any;
    errorMessages: any;
    setErrorMessages: any;
    _listenToProjectChats: any;
  }
);

export const ChatProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [chats, setChats] = useState([] as any);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [questions, setQuestions] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [latestMessage, setLatestMessage] = useState(null);
  const [currentLimit, setCurrentLimit] = useState<number>(30);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const { user } = useAuth();
  const router = useIonRouter();
  // const location = useLocation()
  const [selectedChat, setSelectedChat] = useState<any>();
  const [uniqueChats, setUniqueChats] = useState<ChatType[]>([]);
  const [errorMessages, setErrorMessages] = useState("");
  // const _listenToProjectChats = async (limit: number) => {
  //   const unsubscribe = listenToProjectChats(PROJECT_ID, limit, (data: any) => {
  //     setChats(data);
  //   });
  //   return unsubscribe;
  // };

  const _listenToProjectChats = async (limit: number, user: any) => {
    if (user.uid === undefined) {
      return;
    }
    const unsubscribe = listenToProjectChats(
      PROJECT_ID,
      limit,
      user.uid,
      (chats: ChatType[]) => {
        setChats([...(chats || []), ...(uniqueChats || [])]);
        // const urlQuery = new URLSearchParams(location.search)
        // const chat_id = urlQuery.get('chatId')
        // const selectedChatId = selectedChat?.id

        // if (!selectedChatId) {
        //   return;
        // }
      }
    );
    return unsubscribe;
  };

  useEffect(() => {
    if (!PROJECT_ID) {
      return;
    }
    if (!user) {
      return;
    }

    return () => {
      _listenToProjectChats(30, user);
    };
  }, [PROJECT_ID, user]);

  useEffect(() => {
    if (selectedChat && user) {
      const chat = chats?.find((chat: any) => chat.id === selectedChat);
      if (!chat) {
        getChatByChatId(selectedChat)
          .then((chat) => {
            if (chat) {
              chat.updated_at = new Date().toISOString();
              setChats((prev: any) => [chat, ...prev]);
              setUniqueChats([chat]);
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessages(error.detail);
          });
      }
    }
  }, [selectedChat, user]);

  const createChat = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    push: any
  ) => {
    e.preventDefault();
    await createNewChat({
      projectId: PROJECT_ID,
      name: null,
      metadata: null,
    })
      .then((chat: any) => {
        push(`/chat/${chat.chat_id}`);
        console.log("chat: ", chat);
      })
      .catch((err) => {
        console.log("Error creating chat: ", err);
      });
  };

  const updateChatTitle = async (chat: ChatType, title: string) => {
    if (chat.name !== title) {
      chat.name = title;
      await updateChatName(chat.id, PROJECT_ID, title);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        chats,
        messages,
        setMessages,
        questions,
        setQuestions,
        input,
        setInput,
        messageSent,
        setMessageSent,
        latestMessage,
        setLatestMessage,
        selectedChat,
        setSelectedChat,
        createChat,
        updateChatTitle,
        currentLimit,
        setCurrentLimit,
        disablePagination,
        setDisablePagination,
        errorMessages,
        isLoadingChat,
        setIsLoadingChat,
        setErrorMessages,
        _listenToProjectChats,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
