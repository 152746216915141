import { RouteComponentProps } from "react-router";
import ChatContainer from "../components/ChatContainer";
import { SEO } from "../components/SEO";

interface ChatDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> { }

const ChatPage: React.FC<ChatDetailPageProps> = ({ match }) => {
  return (
    <>
      <SEO title="Chat | Carinfo AI" />
      <ChatContainer chatId={match.params.id} key={match.params.id} />
    </>
  );
};

export default ChatPage;
