
import {useLocation} from 'react-router-dom'
import PageLayout from "../PageLayout";
import { useEffect, useState } from 'react';
import{IonButton
} from "@ionic/react";
import { Router } from 'express';
import { useIonRouter } from '@ionic/react';

const OktaPage: React.FC = () => {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const isApproved:any = params.get('okta_approved')
    const errorType:any = params.get('okta_error')
    const okta_token:any = params.get('token')
    const [message, setMessage] = useState('')
    const router = useIonRouter()
    useEffect(() => {
        console.log(okta_token)
        if(!JSON.parse(isApproved)) {
            switch(errorType) {
                case 'popup_error': 
                setMessage("Error in connecting your Okta account. It looks like your browser's pop-up blocker is preventing us from submitting the form. Please disable the pop-up blocker and try again.")
                    break
                case 'fb_error': 
                setMessage("Your account is not assigned to our client Okta. Please contact your Okta administrator.")
                    break
                default: 
                setMessage('Something went wrong! Please contact your Okta administrator.')
                    break
            }
        }
    }, [params])
    return (
      <PageLayout name={"Login / SignUp"}>
        <div className='page_wrapper h-100 d-flex align-items-center justify-content-center'>
        <div className='message_wrapper text-center' style={{maxWidth: '640px', padding: '20px', border: '1px solid #ececec'}}>
        <div className='container'>
            <p>{message}</p>
            <div className='d-flex align-items-center justify-content-center gap-2' style={{marginTop: '30px'}}>
                
            <IonButton
              expand="block"
              size="default"
              className="sign-okta"
              shape="round"
              onClick={()=>{
                router.push('/')
              }}
            >Home
            </IonButton>
            
            <IonButton
              expand="block"
              size="default"
              className="sign-okta"
              shape="round"
              onClick={()=>{
                localStorage.clear()
                router.push('/okta/login')
              }}
            >Try again
            </IonButton>
            </div>
        </div>
    </div>
        </div>
      </PageLayout>
    );
  };


  
  export default OktaPage;
  