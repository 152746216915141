import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";

import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Search from "./pages/Search";
import LoginPage from "./pages/LoginPage";
import OktaLoginPage from "./pages/okta/login";
import OktaSamlPage from "./pages/okta/saml";
import OktaPage from "./pages/okta";

import React from "react";
// import "react-toastify/dist/ReactToastify.css";
import { setupFirebase } from "./libs/database";
import AccountPage from "./pages/AccountPage";
import ChatPage from "./pages/ChatPage";
import MyChatsPage from "./pages/MyChatsPage";
import { useUser } from "./store/AuthContext";
import AuthRouteGuard from "./store/AuthGaurd";
// import "./theme/styles/card.css";
import "./theme/styles/custom.css";
import "./pages/Page.css";
import { SEO } from "./components/SEO";
import { AboutUs } from "./pages/AboutUs";

setupIonicReact();
setupFirebase();

// this should not trigger a build
const App: React.FC = () => {
  // Your web app's Firebase configuration
  // get user from AuthContext

  const user = useUser();

  return (
    <IonApp>
      <SEO />
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main" className="page_main">
            {/* <Route path="/chats" exact={true}>
              <MyChatsPage />
            </Route> */}
            <Route path="/login">
              <LoginPage />
              {/* {user ? <Redirect to="/account" /> : <LoginPage />} */}
            </Route>
            <Route path="/about-us" exact={true} component={AboutUs} />
            <Route path="/okta/" exact={true} component={OktaPage} />
            <Route path="/okta/login" exact={true} component={OktaLoginPage} />
            <Route path="/okta/saml" component={OktaSamlPage} />
            <Route path="/" exact={true} component={Search} />
            <AuthRouteGuard
              path="/chat/:id"
              exact={true}
              component={ChatPage}
            />
            <AuthRouteGuard path="/chat" exact={true} component={ChatPage} />
            <AuthRouteGuard
              path="/account"
              exact={true}
              component={AccountPage}
              user={user}
            >
              {/* <AccountPage user={user} /> */}
            </AuthRouteGuard>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
