import { HelmetProvider, Helmet } from 'react-helmet-async';

export function SEO({
  title = "Carinfo AI",
  description = "Carinfo AI - The Ultimate Everyday AI. Generate Content Instantly On Any Topic In The Universe",
}) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta charSet="utf-8" />
        <meta name="color-scheme" content="light" />
        <meta name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="msapplication-tap-highlight" content="no" />

        <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />

        {/* <link rel="shortcut icon" type="image/png" href="%PUBLIC_URL%/assets/icon/favicon.png" /> */}
        {/* <link rel="shortcut icon" type="image/png" href={`${process.env.PUBLIC_URL}/assets/icon/favicon.png`} /> */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap"
          rel="stylesheet" />

        {/* add to homescreen for ios */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-title" content="Ionic App" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <script dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','G-X5SFF3JKPV');`,
        }} />
      </Helmet>
    </HelmetProvider>
  );
}
