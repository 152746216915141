import { Fragment, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

interface MessageResponseCardProps {
  responseCard: any[];
}

export default function MessageResponseCard({
  responseCard,
}: MessageResponseCardProps) {
  const [responseContents, setResponseContents] = useState<any[]>([]);

  const renderContent = (content: any) => {
    return Object.entries(content).map(([key, item], index) => {
      if (Array.isArray(item) && item.length) {
        return (
          <div key={index}>
            {item.map((nestedItem: any, nestedIndex: number) => {
              return renderContent(nestedItem); // Return the recursive call
            })}
          </div>
        );
      } else {
        return (
          <div key={index}>
            <ReactMarkdown
              children={`* **${key.replace(/_/g, " ")}**: ${item}`}
            />
          </div>
        );
      }
    });
  };

  useEffect(() => {
    if (responseCard) {
      const responseContents = responseCard
        .filter((data) => data.content)
        .map((data) => data.content);
      setResponseContents(responseContents);
    }
  }, [responseCard]);

  return (
    <>
      {responseContents.map((data, index) => (
        <Fragment key={index}>{renderContent(data)}</Fragment>
      ))}
    </>
  );
}
