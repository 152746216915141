import { useLocation } from 'react-router-dom'
import PageLayout from "../PageLayout";
import { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { AuthProvider } from '../../store/AuthContext'
import Login from "../../components/Login";

const OktaSamlPage: React.FC = () => {
  return (
    <PageLayout name={"Login / SignUp"}>

      <AuthProvider>
        <Login />
      </AuthProvider>


    </PageLayout>
  );
};



export default OktaSamlPage;
