import { IonTextarea, IonIcon } from "@ionic/react";
import { useRef } from "react";
import sendIcon from "../../images/send-icon.svg";
import sendIconOrange from "../../images/send-icon-orange.svg";

export default function MessageInput({
  input,
  setInput,
  handleSubmit,
}: {
  input: string;
  setInput: any;
  handleSubmit: any;
}) {
  const textAreaRef = useRef(null);

  function handleKeyDown(e: any) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (input.trim() === "") return;
      handleSubmit(e);
    } else if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setInput(input + "\n");
    }
  }

  return (
    <div style={{ margin: "10px" }}>
      <form
        onSubmit={handleSubmit}
        style={{
          position: "relative",
        }}
      >
        <IonTextarea
          className="shadow-sm"
          placeholder="Ask Anything"
          value={input}
          autoGrow={true}
          ref={textAreaRef}
          onIonInput={(e) => setInput(e.target.value)}
          enterkeyhint="send"
          style={{
            border: "1px solid #C9CFDB",
            fontSize: "14px",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "65px",
            backgroundColor: "white",
          }}
          onKeyDown={handleKeyDown}
        />
        <IonIcon
          icon={input?.length ? sendIconOrange : sendIcon}
          onClick={(e) => handleSubmit(e)}
          className="message-input-send-icon cursor-pointer"
          style={{
            width: "40px",
            height: "40px",
          }}
        />
      </form>
      <p style={{ fontSize: "12px", fontStyle: "italic" }}>
        <b>Notice:</b> Please do not provide any sensitive or confidential data
        to GenAI.**
      </p>
    </div>
  );
}
