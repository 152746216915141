import React, { useState, useEffect, Fragment } from "react";
import { sendMessage, createChat } from "../../libs/api";
import { listenToChat } from "../../libs/database";
import {
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonRouter,
  useIonToast,
} from "@ionic/react";
import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import { useLocation } from "react-router";
import { useChatContext } from "../../store/ChatContext";

interface ChatContainerProps {
  chatId: string;
}

export default function ChatContainer({ chatId }: ChatContainerProps) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const isMobile = !isPlatform("desktop");
  const location = useLocation();
  const { push } = useIonRouter();
  const { selectedChat, setSelectedChat, errorMessages } = useChatContext();
  const router = useIonRouter();

  const [present] = useIonToast();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!input?.trim()) return;
    setMessageSent(true);
    if (!chatId) {
      createChat(input).then((result) => {
        sendMessage(input, [], result.chat_id);
        router.push(`/chat/${result.chat_id}`);
        // setIsLoadingChat(false);
      });
    } else {
      sendMessage(input, messages, chatId)
        .then(async (data) => {
          if (!location.pathname.includes(chatId)) {
            push(`/chat/${data?.chat_id}`);
          }
        })
        .catch((err) => {
          console.log("Error sending message: ", err);
        });
    }
    setInput("");
  };

  useEffect(() => {
    if (chatId == null) return;
    const unsub = listenToChat(chatId, (data: any) => {
      setSelectedChat(chatId);
      if (data != null) {
        setMessages(data);
        setMessageSent(false);
      } else {
        setMessages([]);
      }
    });
    return () => {
      unsub();
    };
  }, [chatId]);

  useEffect(() => {
    console.log(errorMessages);
    if (errorMessages) {
      present({
        message: errorMessages,
        duration: 1500,
        position: "bottom",
      });
    }
  }, [errorMessages]);

  const scrollToBottom = (messages: any) => {
    const divToScrollTo = document.getElementById(
      `${messages.length - 1 + "-message"}`
    );
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView();
    }
  };

  // scroll to the bottom of the chat when new message is added
  useEffect(() => {
    scrollToBottom(messages);
  }, [messageSent, messages]);

  return (
    <Fragment>
      <IonPage className="chat-container">
        {isMobile && (
          <IonHeader className="ion-no-border">
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle>Chat</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}

        <IonContent>
          {!isMobile && (
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">Chat</IonTitle>
              </IonToolbar>
            </IonHeader>
          )}
          <IonGrid
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "786px",
            }}
          >
            <IonRow
              id="chat-list-scroll-wrap"
              className="slim-scroll"
              style={{
                overflowY: "auto",
              }}
            >
              <IonCol>
                {messages.length === 0 ? (
                  <div className="text-center">
                    <p className="text-muted">No messages yet</p>
                  </div>
                ) : (
                  <MessageList messages={messages} chatId={chatId} />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>

        <IonFooter>
          <IonToolbar
            style={{
              maxWidth: "786px",
              margin: "auto",
            }}
          >
            {messageSent ? (
              <div className="d-flex justify-content-center chat-spinner">
                Sending Message...
              </div>
            ) : (
              <MessageInput
                input={input}
                setInput={setInput}
                handleSubmit={handleSubmit}
              />
            )}
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </Fragment>
  );
}
