import React from "react";
import PageLayout from "./PageLayout";
import harsh from "../images/landing/harsh.png";
import { IonImg, IonText } from "@ionic/react";

export const AboutUs: React.FC = () => {
  return (
    <PageLayout name="About Us">
      <div
        className="mx-auto about-page-container"
        style={{
          maxWidth: "1340px",
          paddingTop: "53px",
          paddingBottom: "53px",
        }}
      >
        <h1 className="about-title mt-0">
          Your ultimate digital hub for everything automotive
        </h1>
        <p
          className="text-center mt-0"
          style={{ fontSize: "16px", marginBottom: "30px" }}
        >
          Created for car enthusiasts by a car enthusiast
        </p>

        <p
          className="text-center mt-0"
          style={{ fontSize: "16px", marginBottom: "50px" }}
        >
          CarInfo.AI, a pioneering platform where automobile passion meets
          cutting-edge technology. From the latest industry news and reviews to
          in-depth analyses of classic and contemporary models, we bring the
          world of cars to your fingertips. Enhanced with AI technology, our
          platform offers personalized content, ensuring that whether you're a
          fan of high-performance sports cars, luxury vehicles, or eco-friendly
          innovations, you'll find information tailored to your interests.
          CarInfo.AI is more than just a website; it's a community of
          like-minded individuals sharing a common passion for cars, driven by
          the spirit of innovation and a love for the road.
        </p>

        <div className="profile-container" style={{ gap: "29px" }}>
          <div className="profile-img">
            <IonImg src={harsh} alt="logo" />
          </div>
          <div className="profile-content">
            <p
              className="mt-0"
              style={{ fontSize: "26px", marginBottom: "15px" }}
            >
              Founder
            </p>
            <div style={{ fontSize: "16px", lineHeight: "24px" }}>
              <p className="mt-0" style={{ marginBottom: "15px" }}>
                I’m Harshveer Vyas, a BASIS Independent Silicon Valley High
                School senior.
              </p>
              <p className="mt-0" style={{ marginBottom: "15px" }}>
                As a very enthusiastic, petrolhead or gearhead, however, you may
                refer to it, a car designer, as well as an auto-photographer,
                I've always had my sights set on creating a website where anyone
                would be able to garner a passion for the drive, the same drive
                that powers hearts across nations when a high-revving
                performance car speeds past.
              </p>
              <p className="mt-0" style={{ marginBottom: "15px" }}>
                This idea sparked during my internship with OdinAI, an
                enterprise AI solutions company, Here I sharpened my expertise
                in prompt engineering, a skill I see as crucial to navigating
                the future's AI-driven marketplace; this experience was crucial
                as they provided me with the platform to launch this website as
                well.
              </p>
              <p className="mt-0" style={{ marginBottom: "15px" }}>
                With a determination as steadfast as my focus, I aim to steer my
                future with the same precision I use to capture the essence of
                cars through my lens. My goal is to become an entrepreneur who
                brings groundbreaking ideas to life, driving change and
                connecting people with their passions. At the core of my journey
                is the belief that marketing is about crafting stories that
                resonate and experiences that last.
              </p>
              <p className="mt-0">
                <a href="mailto:harshkapilvyas@gmail.com">Join me</a> as we
                embark on a venture into the uncharted territories of tomorrow's
                car culture.
              </p>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
