import { Fragment } from "react";
import { MessageBubble } from "./MessageBubble";
import { MessageResponseType } from "../../libs/types";

interface MessageListProps {
  messages: MessageResponseType[];
  chatId: string;
}

export default function MessageList({ messages, chatId }: MessageListProps) {
  return (
    <Fragment>
      {messages?.map((message: any, index: number) => (
        <div key={index + "-message"}>
          <MessageBubble
            type={"human"}
            key={index + "h"}
            id={chatId}
            message={message}
          />
          <MessageBubble
            type={"ai"}
            key={index + "a"}
            id={chatId}
            message={message}
          />
          <div id={index + "-message"} />
        </div>
      ))}
    </Fragment>
  );
}
