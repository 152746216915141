import { getAuth } from "firebase/auth";

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID as string;
console.log("PROJECT_ID: ", PROJECT_ID);
const API_URL = process.env.REACT_APP_API_URL;
console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
export { PROJECT_ID };

export const deleteUser = async (user: any) => {
  return await makeRequest(API_URL + "user/delete", "DELETE", null, {
    "Content-Type": "application/json",
  });
};

export const createNewChat = async ({
  projectId,
  name,
  metadata,
}: {
  projectId: string;
  name: string | null;
  metadata: any;
}) => {
  return await makeRequest(
    API_URL + "chat/create",
    "POST",
    JSON.stringify({
      project_id: projectId,
      name: name ? name : "Untitled",
      metadata: metadata ? metadata : {},
    }),
    { "Content-Type": "application/json" }
  );
};

export const deleteChat = async (
  projectId: string | undefined | string[],
  chatId: string
) => {
  return await makeRequest(
    API_URL + "chat/delete",
    "DELETE",
    JSON.stringify({
      project_id: projectId,
      chat_id: chatId,
    }),
    {
      "Content-Type": "application/json",
    }
  );
};

export const sendMessage = async (
  message: string,
  chat_history: any[],
  chatId: string | null
) => {
  const history = [];
  if (chat_history.length > 0) {
    for (let i = 0; i < chat_history.length; i++) {
      // (human, ai) format
      history.push([chat_history[i].message, chat_history[i].response ?? ""]);
    }
  }

  return await makeRequest(
    API_URL + "chat/message",
    "POST",
    JSON.stringify({
      message: message,
      chat_history: history,
      project_id: PROJECT_ID,
      chat_id: chatId,
      agent_type: "chat_agent",
    }),
    { "Content-Type": "application/json" }
  );
};

export const createChat = async (name: string) => {
  return await makeRequest(
    API_URL + "chat/create",
    "POST",
    JSON.stringify({
      project_id: PROJECT_ID,
      name: name,
    }),
    { "Content-Type": "application/json" }
  );
};

export const getAllChatsForUser = async () => {
  return await makeRequest(
    API_URL + "project/" + PROJECT_ID + "/user/chats",
    "GET",
    null,
    { "Content-Type": "application/json" }
  );
};

export const getChatByChatId = async (chatId: string) => {
  return await makeRequest(
    API_URL + `project/${PROJECT_ID}/chat/${chatId}`,
    "GET",
    null,
    { "Content-Type": "application/json" }
  );
};

export const sendMessageUserFeedback = async (
  userFeedback: boolean | null,
  messageId: string,
  projectId: string,
  chatId: string
) => {
  return await makeRequest(
    API_URL + "chat/message/user/feedback",
    "POST",
    JSON.stringify({
      user_feedback: userFeedback,
      message_id: messageId,
      project_id: projectId,
      chat_id: chatId,
    }),
    { "Content-Type": "application/json" }
  );
};

const makeRequest = async (
  url: string,
  method: string,
  body: any,
  headers: Record<string, string>
) => {
  const auth: any = getAuth();

  const user = auth.currentUser;
  if (!user) {
    return Promise.reject({ error: "User not logged in" });
  }

  // @ts-ignore
  headers["access-token"] = user.accessToken;
  const response = await fetch(url, {
    method: method,
    mode: "cors",
    headers: headers,
    body: body,
  });
  const data = await response.json();
  console.log(data);
  // check if 200 response
  if (response.status === 200) {
    return Promise.resolve(data);
  } else {
    // resolve promise with error
    return Promise.reject(data);
  }
};
