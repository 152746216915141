import SearchBar from "../components/SearchBar";
import PopularQuestions from "../components/PopularQuestions";
import PageLayout from "./PageLayout";
import logo from "../images/landing/CarInfo.webp";
import { useState } from "react";
import { SEO } from "../components/SEO";
import { useAuth } from "../store/AuthContext";
import { useChatContext } from "../store/ChatContext";
import { isPlatform, useIonRouter, useIonToast } from "@ionic/react";
import { createChat, sendMessage } from "../libs";

const SearchPage: React.FC = (children) => {
  const [searchText, setSearchText] = useState<string>("");
  const { isAuthed } = useAuth();
  const [present] = useIonToast();
  const { setQuestions, setIsLoadingChat } = useChatContext();
  const router = useIonRouter();

  const onQuestionSelected = (question: string) => {
    if (!isAuthed) {
      present({
        message: "Please sign in to start asking questions",
        duration: 2000,
      });
      return;
    }

    setSearchText(question);
    setQuestions(question);

    createChat(question).then((result) => {
      setIsLoadingChat(true);
      sendMessage(question, [], result.chat_id);
      setSearchText("");
      setQuestions("");
      router.push(`/chat/${result.chat_id}`);
    });

    setSearchText("");
    setQuestions("");
  };

  return (
    <>
      <SEO
        title="Car Info AI - AI-Powered Automation Solutions"
        description="Unleash the power of Car Info AI"
      />
      <PageLayout name={"Search"}>
        <div className="banner-landing">
          <div
            className="text-center sadhguru-gpt-logo-wrapper"
            style={{ fontSize: "2em" }}
          >
            <img src={logo} width={"145px"} alt="logo" />
          </div>
          <div className="section_searchbar container-s-md w-100">
            <SearchBar
              searchText={
                isAuthed ? searchText : "Sign in to start asking questions"
              }
              setSearchText={setSearchText}
            />
          </div>
        </div>
        <div className="container">
          <div className="search-page-wrapper">
            <div className="section_popular_questions">
              <h4 className="section_title">Common Questions</h4>
              <PopularQuestions onQuestionSelected={onQuestionSelected} />
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default SearchPage;
