import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonButton,
  IonFooter,
  IonToolbar,
  IonAvatar,
  IonSelect,
  IonSelectOption,
  useIonRouter,
  IonImg,
  IonInput,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import {
  logInOutline,
  logInSharp,
  heartOutline,
  heartSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  personCircleOutline,
  personCircleSharp,
  add,
  remove,
} from "ionicons/icons";
import "./Menu.css";
import sadhguruGPTLogo from "../images/landing/sadhguru-gpt-logo.png";
import logout from "../images/landing/icon-logout.svg";
import menuIcon from "../images/landing/menu-icon.svg";
import chatBubble from "../images/landing/ion-chatbubbles.svg";
import deleteIcon from "../images/delete-icon.png";
import pencilIcon from "../images/edit-icon.png";
import { useAuth, useUser } from "../store/AuthContext";
import logo from "../images/landing/brand-logo.svg";
import home from "../images/landing/icon-home.svg";
import yourQuestions from "../images/landing/icon-chat.svg";
import aboutUs from "../images/landing/about-us.svg";
import { useChatContext } from "../store/ChatContext";
import { ChatType } from "../libs/types";
import { PROJECT_ID, deleteChat } from "../libs/api";
import { Fragment, useEffect, useState } from "react";
import googleIcon from "../images/google-logo.svg";
import { signInWithGoogle, signOutAndRedirect } from "../libs/database";
import { handleTruncate } from "../utils";
import tickIcon from "../images/tick.svg";
import DeleteModal from "./modals/delete-modal";
import ChatCard from "./ChatContainer/ChatCard";
import LogOutModal from "./modals/log-out-modal";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  showOnAuth?: boolean;
}

const Menu = () => {
  const location = useLocation();
  const { user, isAuthed, setIsAuthed } = useAuth();
  const { routeInfo, push } = useIonRouter();
  const {
    chats,
    createChat,
    updateChatTitle,
    setCurrentLimit,
    currentLimit,
    _listenToProjectChats,
    setDisablePagination,
    disablePagination,
  } = useChatContext();
  const { onGoogleSignInClicked } = useAuth();
  const [isRenameChat, setIsRenameChat] = useState<boolean>(false);
  const [renameChatName, setRenameChatName] = useState<string>("");
  const [selectedChatId, setSelectedChatId] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const onDeleteChat = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    chatId: string
  ) => {
    e.preventDefault();
    await deleteChat(PROJECT_ID, chatId)
      .then((res) => {
        if (chats.length > 1) {
          push(`/chat/${chats[chats.length - 2].id}`);
        } else {
          push("/chats");
        }
      })
      .catch((err) => {
        console.log("Error deleting chat: ", err);
      });
  };

  const appPages: AppPage[] = [
    {
      title: "Home",
      url: "/",
      iosIcon: heartOutline,
      mdIcon: home,
      showOnAuth: true,
    },
    {
      title: "Your Questions",
      url: chats?.length > 0 ? `/chat/${chats?.[0]?.id}` : "/chat",
      iosIcon: paperPlaneOutline,
      mdIcon: yourQuestions,
      showOnAuth: isAuthed,
    },
  ];

  useEffect(() => {}, [chats]);

  const handleInfiniteScroll = async (ev: any) => {
    setCurrentLimit(currentLimit + 30);
    await _listenToProjectChats(currentLimit + 30);
    // Signal that loading is complete
    ev.target.complete();
  };

  return (
    <IonMenu contentId="main" type="overlay" className="main_menu">
      <div>
        <div>
          <div
            className="text-center sadhguru-gpt-logo-wrapper  cursor-pointer"
            style={{ paddingTop: "22px", paddingBottom: "37px" }}
          >
            <IonImg
              src={logo}
              style={{
                width: "150px",
                margin: "0 auto",
              }}
              alt="logo"
              onClick={() => push("/")}
            />
          </div>

          {routeInfo.pathname.includes("chat") && (
            <IonButton
              expand="block"
              className="new-chat"
              onClick={(e) => createChat(e, push)}
            >
              + New Chat
            </IonButton>
          )}
        </div>

        <div
          className="slim-scroll"
          style={{ maxHeight: "calc(100vh - 171.13px)", overflowY: "auto" }}
        >
          {routeInfo.pathname.includes("chat") ? (
            <Fragment>
              <IonList className="mt-3" role="article">
                {chats?.map((chat: ChatType, index: number) => (
                  <ChatCard
                    chat={chat}
                    setSelectedChatId={setSelectedChatId}
                    key={index}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                  />
                ))}
              </IonList>
            </Fragment>
          ) : (
            <Fragment>
              {/* <div className="text-center" style={{ marginBottom: "20px" }}>
                <IonButton
                  className="d-flex ion-align-items-center gap-2 mx-auto"
                  onClick={onGoogleSignInClicked}
                  shape="round"
                >
                  <img src={googleIcon} alt="google-logo" />
                  <span className="ml-2">Sign In With Google</span>
                </IonButton>
              </div> */}
              <div style={{ marginBottom: "30px" }}>
                {isAuthed ? (
                  <IonButton
                    expand="block"
                    size="default"
                    className="new-chat-okta"
                    shape="round"
                  >
                    + New Chat
                  </IonButton>
                ) : (
                  <IonButton
                    expand="block"
                    size="default"
                    className="sign-okta"
                    shape="round"
                    onClick={() => {
                      window.open(
                        `${API_URL}sso/login?target_url=${window.location.origin}/okta/saml/`,
                        "_self"
                      );
                    }}
                  >
                    Sign in
                  </IonButton>
                )}
              </div>
              <IonList>
                {appPages?.map((appPage, index) => {
                  return (
                    <IonMenuToggle
                      key={index}
                      autoHide={false}
                      style={{
                        display: appPage.showOnAuth ? "block" : "none",
                      }}
                    >
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" md={appPage.mdIcon} />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  );
                })}
              </IonList>
            </Fragment>
          )}
        </div>
      </div>

      {isAuthed && (
        <IonFooter class="pt-2">
          <IonToolbar>
            {/* <IonAvatar slot="start">
                <div className="custom-avatar">
                  {user?.email?.charAt(0).toUpperCase()}
                </div>
              </IonAvatar>
              <IonLabel>{handleTruncate(user?.email, 14)}</IonLabel>
              <IonIcon
                title="Log Out"
                md={logout}
                color="white"
                className="pl-2 cursor-pointer"
                onClick={() => setIsLogOutModalOpen(true)}
              /> */}
            {/* </IonItem> */}
            <IonItem routerLink="/about-us">
              <IonIcon
                slot="start"
                md={aboutUs}
                style={{ width: "18px", height: "18px" }}
                className="ml-1"
              />
              <IonLabel>About Us</IonLabel>
            </IonItem>
          </IonToolbar>
        </IonFooter>
      )}

      <DeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        variant="chat"
        id={selectedChatId}
        onDeleteClicked={onDeleteChat}
      />
      <LogOutModal
        isOpen={isLogOutModalOpen}
        setIsOpen={setIsLogOutModalOpen}
      />
    </IonMenu>
  );
};

export default Menu;
