import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import { useState } from "react";
import tickIcon from "../../images/tick.svg";
import chatBubble from "../../images/landing/ion-chatbubbles.svg";
import deleteIcon from "../../images/delete-icon.png";
import pencilIcon from "../../images/edit-icon.png";
import { useLocation } from "react-router";
import { useChatContext } from "../../store/ChatContext";

export default function ChatCard({
  chat,
  setSelectedChatId,
  setIsDeleteModalOpen,
}: {
  chat: any;
  setSelectedChatId: any;
  setIsDeleteModalOpen: any;
}) {
  const [isRenameChat, setIsRenameChat] = useState(false);
  const [renameChatName, setRenameChatName] = useState(chat.name || "Untitled");
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { updateChatTitle } = useChatContext();

  const onEditChatClicked = (e: any, chatId: string) => {
    e.preventDefault();
    setIsRenameChat(true);
  };

  return (
    <IonItem
      className="side-chat-list-item p-hover"
      style={{
        backgroundColor: location.pathname.includes(chat?.id) ? "#F5F5F5" : "",
      }}
      routerLink={`/chat/${chat.id}`}
      routerDirection="none"
      lines="none"
      detail={false}
    >
      <div className="d-flex">
        <IonIcon md={chatBubble} />
      </div>
      {isRenameChat ? (
        <div className="d-flex gap-2 ion-align-items-center ml-3">
          <input
            type="text"
            value={renameChatName}
            onChange={(e) => setRenameChatName(e.target.value)}
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              fontSize: "15px",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsRenameChat(false);
                if (renameChatName.trim() !== "") {
                  updateChatTitle(chat, renameChatName);
                } else {
                  setRenameChatName(chat.name || "Untitled");
                }
              }
            }}
          />
          <img
            src={tickIcon}
            width={18}
            height={18}
            onClick={(e) => {
              e.preventDefault();
              setIsRenameChat(false);
              if (renameChatName.trim() !== "") {
                updateChatTitle(chat, renameChatName);
              } else {
                setRenameChatName(chat.name || "Untitled");
              }
            }}
            alt="tick"
          />
        </div>
      ) : (
        <>
          <IonLabel className="ml-3">{chat.name || "Untitled"}</IonLabel>
          <div className="d-flex gap-2 ion-align-items-center">
            <img
              className="hover-visible"
              src={pencilIcon}
              width={28}
              height={28}
              alt="edit"
              onClick={(e) => onEditChatClicked(e, chat.id)}
            />
            <img
              className="hover-visible"
              src={deleteIcon}
              width={24}
              height={24}
              alt="delete"
              onClick={() => {
                setIsDeleteModalOpen(true);
                setSelectedChatId(chat.id);
              }}
            />
          </div>
        </>
      )}
    </IonItem>
  );
}
