import Login from "../components/Login";
import PageLayout from "./PageLayout";
// import 
import { AuthProvider } from '../store/AuthContext'

const LoginPage: React.FC = () => {

  return (
    <PageLayout name={"Login / SignUp"}>

      <AuthProvider>
        <Login />
      </AuthProvider>


    </PageLayout>
  );
};

export default LoginPage;
