import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useIonRouter } from "@ionic/react";
import { signInWithGoogle } from "../libs/database";
import { useLocation } from "react-router-dom";
interface AuthContextValue {
  user: any | null;
  setUser: React.Dispatch<React.SetStateAction<any | null>>;
  isAuthed: boolean;
  setIsAuthed: React.Dispatch<React.SetStateAction<boolean>>;
  onGoogleSignInClicked?: (e: any) => boolean;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  let _user = localStorage.getItem("fbUser");
  if (_user != null) {
    _user = JSON.parse(_user);
  }
  const router = useIonRouter();
  const [user, setUser] = useState<any | null>(_user);
  const [isAuthed, setIsAuthed] = useState<boolean>(user?.email ? true : false);

  useEffect(() => {
    const subs: any[] = [];

    // const {okta_error} = router.query

    const userAgent = window?.navigator.userAgent;
    console.log("userAgent", userAgent);
    console.log(
      "page loaded, checking auth state, if the auth state is null then we setup an anonymous user"
    );
    // const auth = getAuth();
    // const subscription = onAuthStateChanged(auth, (fbUser) => {
    FirebaseAuthentication.addListener(
      "authStateChange",
      ({ user: fbUser }) => {
        // console.log("auth state changed", fbUser);
        console.log("auth state changed", fbUser);
        if (fbUser) {
          localStorage.setItem("fbUser", JSON.stringify(fbUser));
          setUser(fbUser);
          setIsAuthed(true)
          // if (!fbUser.emailVerified) {
          //     // handleShowVerifyEmailModal();
          // }
        } else {
          // subscription();
          for (const i in subs) {
            // check if it is a function
            if (typeof subs[i] === "function") {
              subs[i]();
            }
          }
          setIsAuthed(false)
          // localStorage.removeItem("fbUser");
          // localStorage.removeItem("userInfo");
          // localStorage.removeItem("projects");
          // setUser(null);
          FirebaseAuthentication.signInAnonymously().then((fbUser) => {
            console.log("signInAnonymously", fbUser);
            // localStorage.setItem("fbUser", JSON.stringify(fbUser));
            // setUser(fbUser);
          });
        }
      }
    );

    return () => {
      console.log("unsubscribing from auth state change");

      for (const i in subs) {
        // check if it is a function
        if (typeof subs[i] === "function") {
          subs[i]();
        }
      }
    };
  }, []);



  const onGoogleSignInClicked = (e: any) => {
    e.preventDefault();
    // check user agent
    const ua = window.navigator.userAgent;
    if (ua.includes("Bytedance")) {
      console.log("this is a bytedance browser");
      alert(
        "Unable to login with Google from tiktok, please use a different browser."
      );
      return false;
    }

    signInWithGoogle()
      .then((result: any) => {
        console.log("result", result);
        router.push("/");
      })
      .catch((error: string) => {
        console.log("error", error);
      });

    return true;
  };

  const value: AuthContextValue = {
    user,
    setUser,
    isAuthed,
    setIsAuthed,
    onGoogleSignInClicked,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const useUser = (): any | null => {
  const { user } = useAuth();
  return user;
};

export const useIsAuthed = (): boolean => {
  const { isAuthed } = useAuth();
  return isAuthed;
};
