import { IonModal, IonHeader, IonContent, IonButton } from "@ionic/react";
import closeIcon from "../../images/icon-close.png";

function DeleteModal({
  isOpen,
  setIsOpen,
  variant,
  onDeleteClicked,
  id,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  variant: string;
  onDeleteClicked: any;
  id?: string;
}) {
  return (
    <div>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        className={`poppins-regular delete-${variant}-modal`}
      >
        <IonHeader className="px-4 py-4 forgot-pwd-header ion-no-border">
          <div className="d-flex ion-justify-content-between ion-align-items-center">
            <h3>Delete {variant}?</h3>
            <div>
              <img
                src={closeIcon}
                alt="close-icon"
                onClick={() => setIsOpen(false)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </IonHeader>
        <IonContent className={`delete-${variant}-modal-content`}>
          <div className="px-4 py-4 forgot-pwd-content poppins-regular">
            <div className="mb-3">
              <p>
                Are you sure you want to delete your {variant}? This action
                cannot be undone.
              </p>
            </div>
            <div className="text-end">
              <IonButton
                onClick={(e) => {
                  if (variant === "chat") {
                    onDeleteClicked(e, id);
                  }
                  setIsOpen(false);
                }}
                color={"danger"}
              >
                Delete {variant}
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
}

export default DeleteModal;
