import React, { useState, useEffect } from "react";
import { useIonRouter } from "@ionic/react";
import { signInWithEmail, signInWithGoogle } from "../libs";
import { FirebaseErrors } from "../libs/firebase-errors";
import { ColorRing } from 'react-loader-spinner'
import { useLocation } from "react-router";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const router = useIonRouter();
  const [firebaseErrorMessage, setFirebaseErrorMessage] = useState("");


  // set a timeout to clear the error message
  useEffect(() => {
    if (firebaseErrorMessage) {
      setTimeout(() => {
        setFirebaseErrorMessage("");
      }, 8000);
    }
  }, [firebaseErrorMessage]);

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const okta_fb_token = params.get('token')

  return (
    <div className="d-flex flex-row align-items-center justify-content-center h-100 gap-3" style={{ flexDirection: 'column' }}>
      <ColorRing width={62} height={62} />
      <div className="text-center">Authenticating...</div>
      {/* {authMessage} */}
    </div>
  );
}
export default Login;
