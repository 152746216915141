import {
  IonModal,
  IonHeader,
  IonContent,
  IonButton,
  useIonRouter,
} from "@ionic/react";
import closeIcon from "../../images/icon-close.png";
import { signOutAndRedirect } from "../../libs";

function LogOutModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { push } = useIonRouter();
  return (
    <div>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        className={`poppins-regular log-out-modal`}
      >
        <IonHeader className="px-4 py-4 forgot-pwd-header ion-no-border">
          <div className="d-flex ion-justify-content-between ion-align-items-center">
            <h3>Logout?</h3>
            <div>
              <img
                src={closeIcon}
                alt="close-icon"
                onClick={() => setIsOpen(false)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </IonHeader>
        <IonContent className="log-out-modal-content">
          <div className="px-4 py-4 forgot-pwd-content poppins-regular">
            <div className="mb-3">
              <p>Are you sure you want to logout?</p>
            </div>
            <div className="text-end">
              <IonButton
                onClick={(e) => {
                  setIsOpen(false);
                  signOutAndRedirect(e, push);
                }}
                color={"danger"}
              >
                Log Out
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
}

export default LogOutModal;
