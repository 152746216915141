import React, { ReactNode } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/react";

interface PageLayoutProps {
  children: ReactNode;
  name: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children, name }) => {
  const isMobile = !isPlatform("desktop");
  console.log("isMobile: ", isMobile);
  return (
    <IonPage>
      {isMobile && (
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonTitle>{name}</IonTitle>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen>{children}</IonContent>
    </IonPage>
  );
};

export default PageLayout;
