import { IonSearchbar, IonIcon } from "@ionic/react";
import { useState } from "react";
import { createChat, sendMessage } from "../libs/api";
import { useIonRouter } from "@ionic/react";
import searchIcon from "../images/landing/search-icon.svg";
import { useAuth } from "../store/AuthContext";
import { useChatContext } from "../store/ChatContext";
const API_URL = process.env.REACT_APP_API_URL;

function SearchBar({
  searchText,
  setSearchText,
}: {
  searchText: string;
  setSearchText: any;
}) {
  const router = useIonRouter();
  const { isAuthed } = useAuth();
  const { setQuestions, setIsLoadingChat } = useChatContext();
  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const [validations, setValidations] = useState({
    searchText: {
      error: false,
    },
  });

  const onSearchSubmit = (e: any) => {
    console.log("onSearchSubmit", e);
    e.preventDefault();
    if (isAuthed) {
      // if the search text is empty, we show an error message
      if (searchText.trim() === "") {
        setValidations({
          searchText: {
            error: true,
          },
        });
        // set a timer of 5 seconds to clear the error message
        setTimeout(() => {
          setValidations({
            searchText: {
              error: false,
            },
          });
        }, 5000);
        return;
      }
      setQuestions(searchText);
      // router.push("/chat");
      setIsLoadingChat(true);
      // create a new chat when user redirected from the search box
      // first create a new chat
      createChat(searchText).then((result) => {
        sendMessage(searchText, [], result.chat_id);
        router.push(`/chat/${result.chat_id}`);
        setSearchText("");
        setQuestions("");
        // setIsLoadingChat(false);
      });

      // We submit the search with 0 chat history and no id of a chat to start a new one
      // sendMessage(searchText, [], null).then((result) => {
      //   console.log("result", result);
      //   router.push(`/chat/${result.chat_id}`);
      //   setSearchText("");
      // });
    } else {
      window.open(`${API_URL}sso/login`, "_self");
    }
  };

  return (
    <form
      onSubmit={onSearchSubmit}
      style={{
        paddingTop: "2rem",
      }}
    >
      <IonSearchbar
        value={searchText}
        placeholder="Ask me anything about cars"
        showClearButton="never"
        onIonInput={onSearchChange}
        enterkeyhint={"send"}
      >
        <IonIcon
          icon={searchIcon}
          onClick={onSearchSubmit}
          className="custom-searchbar-search-icon cursor-pointer"
        />
      </IonSearchbar>
      {validations.searchText.error && (
        <div
          className="text-danger"
          style={{
            paddingLeft: "2rem",
          }}
        >
          Please enter a valid search text
        </div>
      )}
    </form>
  );
}

export default SearchBar;
