import React, { useState, useEffect, CSSProperties } from "react";
import aaIcon from "../../images/landing/aa.svg";
import { IonImg, useIonToast } from "@ionic/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { IonAvatar } from "@ionic/react";
import likeIcon from "../../images/like-icon.png";
import dislikeIcon from "../../images/dislike-icon.png";
import likeOrange from "../../images/like-orange.png";
import dislikeOrange from "../../images/dislike-orange.png";
import copyIcon from "../../images/copy-icon.png";
import { sendMessageUserFeedback } from "../../libs/api";
import MessageResponseCard from "./MessageResponseCard";
import { MessageResponseType } from "../../libs/types";

const aiStyles: CSSProperties = {
  wordWrap: "break-word",
  textAlign: "left",
  padding: "10px",
  background: "#FBFBFC",
  borderRadius: "0px 15px 15px 15px",
  marginBottom: "20px",
};

const userStyles: CSSProperties = {
  wordWrap: "break-word",
  textAlign: "left",
  padding: "10px",
  background: "#FBFBFC",
  borderRadius: "0px 15px 15px 15px",
  marginBottom: "20px",
};

const copyToClipboard = (e: any, value: string, present: any) => {
  window.navigator.clipboard.writeText(value);
  present({
    message: "Copied to clipboard",
    duration: 1500,
    position: "bottom",
  });
};

interface FeedbackSectionProps {
  message: MessageResponseType;
  onUserFeedbackClicked: (e: any, feedback: boolean | null) => void;
}

const FeedbackSection = ({
  message,
  onUserFeedbackClicked,
}: FeedbackSectionProps) => {
  const [present] = useIonToast();

  return (
    <div className="d-flex ion-justify-content-between mt-3">
      <div
        className="d-flex"
        style={{
          gap: "10px",
        }}
      >
        {[
          {
            image: message?.user_feedback === true ? likeOrange : likeIcon,
            onClick: (e: any) => {
              onUserFeedbackClicked(e, true);
            },
          },
          {
            image:
              message?.user_feedback === false ? dislikeOrange : dislikeIcon,
            onClick: (e: any) => {
              onUserFeedbackClicked(e, false);
            },
          },
        ].map((item, index) => {
          return (
            <IonImg
              key={index}
              src={item.image}
              style={{ width: "20px" }}
              onClick={item.onClick}
              className="cursor-pointer"
            />
          );
        })}
      </div>
      <IonImg
        src={copyIcon}
        style={{ width: "28px" }}
        onClick={(e) => copyToClipboard(e, message.response, present)}
        className="cursor-pointer"
      />
    </div>
  );
};

interface MessageBubbleProps {
  type: string;
  id: string;
  message: MessageResponseType;
}

export const MessageBubble = ({ type, id, message }: MessageBubbleProps) => {
  const [sourceList, setSourceList] = useState<any>([]);
  const [present] = useIonToast();

  useEffect(() => {
    if (message?.sources) {
      const _set = new Set(message.sources);
      // @ts-ignore
      const unique = [..._set];
      setSourceList(unique);
    }
  }, [message]);

  const onUserFeedbackClicked = async (e: any, feedback: boolean | null) => {
    e.preventDefault();
    if (message) {
      if (message?.user_feedback === feedback) {
        console.log("You already gave this feedback");
        feedback = null;
      }
      sendMessageUserFeedback(feedback, message.id, message.project_id, id)
        .then((res: any) => {
          console.log(res);
          present({
            message: res?.message,
            duration: 1500,
            position: "bottom",
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {type !== "ai" ? (
        <div
          className="d-flex mb-3"
          style={{
            textAlign: "right",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IonAvatar slot="start" style={{ width: "32px", height: "32px" }}>
            <div className="custom-avatar">C</div>
          </IonAvatar>
          {new Date(message?.created_at * 1000).toLocaleTimeString()}
        </div>
      ) : (
        <div
          className="d-flex mb-3"
          style={{
            textAlign: "right",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <img src={aaIcon} style={{ width: "30px" }} alt="logo" /> */}
          {new Date(message?.created_at * 1000).toLocaleTimeString()}
        </div>
      )}

      {type === "ai" ? (
        <div key={id + "-message"}>
          <div style={aiStyles}>
            <div>
              {message.response ? (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  children={message.response}
                  className="react-markdown-message"
                />
              ) : (
                <>...</>
              )}
            </div>
            {!(
              message?.response_cards && message?.response_cards?.length > 0
            ) && (
              <FeedbackSection
                message={message}
                onUserFeedbackClicked={onUserFeedbackClicked}
              />
            )}
          </div>

          {message?.response_cards && message?.response_cards?.length > 0 && (
            <div style={aiStyles}>
              <MessageResponseCard responseCard={message?.response_cards} />
              <FeedbackSection
                message={message}
                onUserFeedbackClicked={onUserFeedbackClicked}
              />
            </div>
          )}
        </div>
      ) : (
        <div key={id + "-message"} style={userStyles}>
          <div className=""></div>
          <div className="user-message" style={{ whiteSpace: "pre-line" }}>
            <ReactMarkdown children={message.message} />
          </div>
        </div>
      )}
    </>
  );
};
