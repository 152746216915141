import { Capacitor } from "@capacitor/core";
import {
  getFirestore,
  collection,
  getDoc,
  addDoc,
  onSnapshot,
  terminate,
  enableMultiTabIndexedDbPersistence,
  orderBy,
  limit,
  query,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import { initializeApp, getApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
  linkWithCredential,
  signInWithCredential,
  signInWithEmailAndPassword,
  Unsubscribe,
  indexedDBLocalPersistence,
  initializeAuth,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { PROJECT_ID } from "./api";


const WEB_ENV = process.env.REACT_APP_ENV || "PROD";
console.log("WEB_ENV: ", WEB_ENV);

let app: any = null;
let db: any = null;
let auth: any = null;

export const setupFirebase = () => {
  let firebaseConfig;
  if (WEB_ENV == "PROD") {
    firebaseConfig = {
      apiKey: "AIzaSyBl64WQ6SgWzhoXSW-QKxriO5_YSBdSrf8",
      authDomain: "odin-ai-production.firebaseapp.com",
      projectId: "odin-ai-production",
      storageBucket: "odin-ai-production.appspot.com",
      messagingSenderId: "181233548789",
      appId: "1:181233548789:web:a9d94e6ccd9b0fc19b74bc",
      measurementId: "G-6GSV1GLL6W",
    };
  } else {
    firebaseConfig = {
      apiKey: "AIzaSyANb7euT8jR1MyXbTFcviaXy2wj47xeMqE",
      authDomain: "ai-content-writer-e0839.firebaseapp.com",
      projectId: "ai-content-writer-e0839",
      storageBucket: "ai-content-writer-e0839.appspot.com",
      messagingSenderId: "1069018982835",
      appId: "1:1069018982835:web:c2ed3b783dbdfd3b9483c6",
      measurementId: "G-8XD1X9ELXM",
    };
  }
  
  // console.log(firebaseConfig);
  // Initialize Firebase
  if (app == null) {
    console.log("setup app");
    app = initializeApp(firebaseConfig);
  }

  if (db == null) {
    console.log("setup db");
    db = getFirestore(app);
    if (!Capacitor.isNativePlatform()) {
      if (!(db as any)._firestoreClient) {
        enableMultiTabIndexedDbPersistence(db)
          .then(() => console.log("Persistence enabled!"))
          .catch((err) => console.error(err.message));
      }
    }
  }
};

export const getFirebaseAuth = () => {
  if (Capacitor.isNativePlatform()) {
    if (auth == null) {
      console.log("native auth");
      auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
      });
    }
    return auth;
  } else {
    return getAuth();
  }
};

export const createCheckoutSession = async (priceId: string) => {
  // const auth = getFirebaseAuth();
  const auth = getAuth();
  if (auth.currentUser == null) {
    throw new Error("Not logged in");
  }

  const docRef = await addDoc(
    collection(db, "customers", auth.currentUser.uid, "checkout_sessions"),
    {
      price: priceId,
      success_url: window.location.origin + "/billing?success=true",
      cancel_url: window.location.origin + "/billing?success=false",
    }
  );
  // Wait for the CheckoutSession to get attached by the extension
  return new Promise((resolve, reject) => {
    onSnapshot(docRef, (snap) => {
      const data = snap.data();

      if (data?.error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        // alert(`An error occured: ${data.error.message}`);
        return reject(data);
      }

      if (data?.sessionId) {
        return resolve(data);
      }
    });
  });
};

export const updateChatName = async (
  chatId: string,
  projectId: string,
  title: string
) => {
  try {
    await updateDoc(
      doc(db, "projects/" + projectId + "/chats/" + chatId),
      "name",
      title
    );
  } catch (e) {
    console.log("Error updating chat name", e);
  }
};

export const listenToChat = (chatId: string, callback: any) => {
  console.log("listen to chat", chatId);
  const chatPath = "projects/" + PROJECT_ID + "/chats/" + chatId + "/messages";
  const collectionRef = query(
    collection(db, chatPath),
    orderBy("created_at", "desc"),
    limit(30)
  );
  const unsubscribe = onSnapshot(collectionRef, (snapshot: any) => {
    const data: any[] = [];
    snapshot.forEach((doc: { data: () => any; id: any }) => {
      const d = doc.data();
      d.id = doc.id;
      data.push(d);
    });
    data.reverse();
    callback(data);
  });

  return unsubscribe;
};

export const listenToProjectChats = (
  projectId: string,
  limitValue: number,
  uid: string,
  callback: any
) => {
  const projectPath = "projects/" + projectId + "/chats";
  const collectionRef = query(
    collection(db, projectPath),
    orderBy("updated_at", "desc"),
    // where("created_by", "==", uid),
    limit(limitValue)
  );
  const unsubscribe = onSnapshot(
    collectionRef,
    (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        const d = doc.data();
        d.id = doc.id;
        return d;
      });
      callback(data);
    },
    (error) => {
      console.log("Error while listening to chats", error);
      callback(null);
    }
  );
  return unsubscribe;
};

export const getFirestoreDoc = async (reference: any) => {
  return await getDoc(reference);
};

export async function loginTestUser() {
  const auth = getFirebaseAuth();
  return await signInWithEmailAndPassword(
    auth,
    "testuser4@gmail.com",
    "123456789"
  ).then((userCredential) => {
    return userCredential.user;
  });
}

export const firebaseSignUp = async (email: string, password: string) => {
  const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
    email: email,
    password: password,
  });
  const credential = EmailAuthProvider.credential(email, password);
  const auth = getAuth();
  await signInWithCredential(auth, credential);

  // .then(async (userCredential: any) => {
  //   await FirebaseAuthentication.sendEmailVerification();
  //   return userCredential;
  // })
  // .catch((error) => {
  //   console.error(error);
  // });
  return Promise.resolve(result);
};

export const signInWithGoogle = async () => {
  // 1. Create credentials on the native layer
  const result = await FirebaseAuthentication.signInWithGoogle();
  // 2. Sign in on the web layer using the id token
  const credential = GoogleAuthProvider.credential(
    result.credential?.idToken,
    result.credential?.accessToken
  );
  const auth = getAuth();
  // const sign = await signInWithCredential(auth, credential);
  if (auth.currentUser == null) {
    throw new Error("Not logged in");
  }

  try {
    await linkWithCredential(auth.currentUser, credential);
  } catch (e) {
    console.log("link error", e);
  }
  // console.log("sign", sign);

  return Promise.resolve(result);
};

export const signInWithEmail = async (email: string, password: string) => {
  // 1. Create credentials on the native layer
  // const result = await FirebaseAuthentication.signInWithGoogle();
  // 2. Sign in on the web layer using the id token
  // const credential = GoogleAuthProvider.credential(result.credential?.idToken);

  const result = await FirebaseAuthentication.signInWithEmailAndPassword({
    email,
    password,
  });

  const credential = EmailAuthProvider.credential(email, password);
  const auth = getAuth();
  await signInWithCredential(auth, credential);

  return Promise.resolve(result);
  // .then((userCredential) => {
  //   setIsLoading(false);
  //   log("login", { method: "email" });
  //   const auth = getAuth();
  // })
  // .catch((error) => {
  //   log("login_error", { method: "email" });
  //   setIsLoading(false);
  //   setFirebaseErrorMessage(FirebaseErrors(error));
  // });
};

export const signOutAndRedirect = async (e: any, push: any) => {
  console.log("sign out and redirect");
  try {
    await FirebaseAuthentication.signOut();
    terminate(db);
    db = null;
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        push("/");
      })
      .catch((error) => {
        console.log("Error signing out: ", error);
      });
  } catch (e) {
    console.log("sign out error", e);
  }
};

export const signOutFB = async () => {
  // 1. Sign out on the native layer
  await FirebaseAuthentication.signOut();
  // 1. Sign out on the web layer
  terminate(db);
  db = null;

  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // The user is now signed out
      console.log("User signed out");
      // dont do anything let the app navigate to /
    })
    .catch((error) => {
      console.log("Error signing out: ", error);
    });
};

export const createEmailUser = async (email: string, password: string) => {
  // const auth = getFirebaseAuth();

  const auth = getAuth();
  var credential = EmailAuthProvider.credential(email, password);
  if (auth.currentUser == null) {
    throw new Error("Not logged in");
  }
  linkWithCredential(auth.currentUser, credential).then(
    function (user) {
      console.log("Anonymous account successfully upgraded", user);
      FirebaseAuthentication.sendEmailVerification();
    },
    function (error) {
      console.log("Error upgrading anonymous account", error);
    }
  );

  // 2. Links the credential to the currently signed in user
  // (the anonymous user).
  // auth.currentUser.linkWithCredential(credential).then(function (user) {
  //   console.log("Anonymous account successfully upgraded", user);
  // }, function(error) {
  //   console.log("Error upgrading anonymous account", error);
  // });

  // FirebaseAuthentication.createUserWithEmailAndPassword({
  //   email: email,
  //   password: password,
  // })
  //   .then(async (userCredential) => {
  //     //   log("sign_up", { method: "email" });
  //     //   const db = getDatabase();
  //     //   const userRef = ref(db, "users/" + userCredential.user.uid);
  //     //   const refParam = localStorage.getItem("referenceParam");
  //     //   logSignUp();
  //     //   await set(userRef, {
  //     //     credits_used: 0,
  //     //     referer: refParam,
  //     //     name: email.split("@")[0].replace(".", ""),
  //     //     email: email,
  //     //   });
  //     await FirebaseAuthentication.sendEmailVerification();
  //     //   router.push("/dashboard");
  //     return;
  //   })
  //   .catch((error) => {
  //     //   log("sign_up_error", { method: "email" });
  //     console.error(error);
  //     //   setIsLoading(false);
  //     //   setFirebaseErrorMessage(FirebaseErrors(error));
  //   });
};

const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser();
  return result.user;
};

export const sendEmailVerification = async () => {
  const currentUser = getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.sendEmailVerification();
};

export const applyActionCode = async (code: string) => {
  await FirebaseAuthentication.applyActionCode({ oobCode: code });
};

export const sendPasswordResetEmail = async (email: string) => {
  await FirebaseAuthentication.sendPasswordResetEmail({ email });
};

export const deleteUserAccount = async () => {
  const currentUser = getCurrentUser();
  if (!currentUser) {
    return;
  }
  await FirebaseAuthentication.deleteUser();
};

export const signInWithToken = async (token: any) => {
  const auth = getAuth();
  // console.log(token);
  const sign = await signInWithCustomToken(auth, token);
  // console.log("sign", sign);

  return Promise.resolve(sign);
};

export { app };
